import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

export function loginByUsername (username, password) {
  // console.log('on api loginByUsername')
  var data = {
    identifier: username,
    password: password
  }
  // console.log('on api loginByUsername',data)
  var uri = _GLOBAL.URLBACKEND + '/auth/local'
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
  // console.log(response)
  return response
}

/* AS JWT TOKEN NO NEED TO LOGOUT USING STRAPI
export function logout() {
  return request({
    url: '/login/logout',
    method: 'post'
  })
}
*/

export function getUserInfo (token, userid) {
  // console.log('on api getUserInfo')
  if (userid.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/users/' + userid
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
  // console.log(response)
  return response
}

export function forgotPassword (mailaddr) {
  // console.log('on api forgotPassword')
  if (mailaddr.length === 0) return null
  var uri = _GLOBAL.URLBACKEND + '/cusqueries/forgotuser'
  var data = {
    email: mailaddr
  }
  console.log(uri)
  console.log(data)
  var response = request({
    url: uri,
    method: 'post',
    data
  })
  // console.log(response)
  return response
}

export function resetPassword (code, pass, passconfirm) {
  // console.log('on api resetPassword')
  if (code.length === 0) return null
  if (pass.length === 0) return null
  if (passconfirm.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/auth/reset-password'
  var data = {
    code: code,
    password: pass,
    passwordConfirmation: passconfirm
  }
  // console.log(uri)
  // console.log(data)
  var response = request({
    url: uri,
    method: 'post',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
  // console.log(response)
  return response
}
