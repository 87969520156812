import request from '@/utils/request'
import { _GLOBAL } from '@/api/server'
import secureStorage from '@/utils/secureStorage'

export function getUserPosRoles(token, userid) {
  // console.log('on api getUserPosRoles')

  if (userid <= 0) return null

  var data = ''
  var uri = _GLOBAL.URLBACKEND + '/hruserpos/' + userid
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
  // console.log(response)
  return response
}